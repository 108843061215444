<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="searchFrom" class="demo-form-inline searchFrom" size="small">
            <el-form-item label="模版名称">
              <el-input
                v-model="searchFrom.organizationName"
                :clearable="true"
                placeholder="请输入模版名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="模版类型">
              <el-select v-model="searchFrom.organizationCode" clearable placeholder="请选择系统通知">
                <el-option label="全部 " value=""></el-option>
                <el-option v-for="(item,index) in modelList" :key="index" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" type="primary" :disabled="disabledSearch" @click="getAddNoticeList(1)">查询</el-button>
            </el-form-item>
            <!-- <el-form-item>
              <el-button plain icon="el-icon-plus" type="primary" @click="add">添加</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain icon="el-icon-delete" type="danger" @click="handleDelete">删除</el-button>
            </el-form-item> -->
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card shadow="always" class="card">
      <div>
        <el-button plain icon="el-icon-plus" type="primary" @click="add">添加</el-button>
        <el-button plain icon="el-icon-delete" type="danger" @click="handleDelete">删除</el-button>
      </div>
      <div class="table-block">
        <div class="Selectnum">已选中 <span style="color:#5479FF">{{multipleSelection.length}}</span> 条信息</div>
      <el-table :data="tableData" ref="multipleTable" @row-click="handleClickTableRow" v-loading="loading" :header-cell-style="{'text-align': 'center','background': '#EEF1FC', 'color': '#999'}" :cell-style="{'text-align':'center'}" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" fixed width="50"></el-table-column>
        <el-table-column prop="templateName" label="模版名称"> </el-table-column>
        <el-table-column prop="beginDate" width="150" label="开始时间"> </el-table-column>
        <el-table-column prop="endDate" width="150" label="结束时间"> </el-table-column>
        <el-table-column prop="createBy" width="120" label="添加人"> </el-table-column>
        <el-table-column prop="createTime" label="添加时间"> </el-table-column>
        <el-table-column prop="state" width="80" label="状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-color="#5479FF"
              inactive-color="#D5D5D5"
              active-value="0"
              @change="changeStatus(scope.row)"
              inactive-value="1">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="180">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" class="updatebutton" type="text" size="small">修改</el-button>
            <!-- <el-button v-show="scope.row.status == 0" @click="changeStatus(scope.row, 1)" type="text" size="small">禁用</el-button>
            <el-button v-show="scope.row.status == 1" @click="changeStatus(scope.row, 0)" type="text" size="small">启用</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      background>
    </el-pagination>
    </el-card>
    <addModel :info="info" v-if="showAddModel" @closeAddmodel="closeAddmodel"></addModel>
  </div>
</template>

<script>
import { postRequest, postRequestAnthor } from '@/api'
import addModel from '@/views/notice/components/addModel'
export default {
  data() {
    return {
      searchFrom: {
        organizationName: '',
        organizationCode: '',
      },
      showAddModel: false,
      tableData: [],
      multipleSelection: [],
      total:null,
      pageNo: 1,
      pageSize: 10,
      loading: true,
      disabledSearch: false,
      info: {},
      modelList: []
    }
  },
  components: { addModel },
  mounted(){
    this.getAddNoticeList()
    postRequest('/dict/getDictByCategory/', {dictionaryCategoryCode: '10'}).then(res=>{
      console.log(res, '资讯模版')
      this.modelList = res
    })
  },
  methods: {
    add() {
      console.log(123)
      this.info = ''
      this.showAddModel = true
    },
    closeAddmodel() {
      this.showAddModel = false
      this.getAddNoticeList()
    },
    handleClick (val) {
      this.info = val
      this.showAddModel = true
    },
    // 点击行选中
    handleClickTableRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 选中项
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val
    },
    changeStatus(val){
      console.log(val)
      let data = {
        id: val.id,
        status: val.status
      }
      postRequestAnthor('/noticetemplate/update', data).then(res=>{
        console.log(res, '禁用/启用')
        this.$message({
          message: '状态修改成功',
          type: 'success'
        })
        // this.getAddNoticeList()
      }).catch(err=>{
        this.loading = false
      })
    },
    handleDelete(){
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          if (this.multipleSelection.length <= 0) {
            this.$message({
              message: '请选择删除项',
              type: 'warning'
            })
          }
          let data = {
            userId: '1',
            ids: []
          }
          this.multipleSelection.map(item => {
            data.ids.push(item.id)
          })
          postRequestAnthor('/noticetemplate/deletes', data).then(res=>{
            console.log(res, '删除结果')
            this.getAddNoticeList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    getAddNoticeList(no){
      this.disabledSearch = true
      this.loading = true
      let data = {
        templateName: this.searchFrom.organizationName ? this.searchFrom.organizationName : '',
        noticeType: this.searchFrom.organizationCode ? this.searchFrom.organizationCode : '',
        pageNo: no ? no : this.pageNo,
        pageSize: this.pageSize
      }
      postRequestAnthor('/noticetemplate/querynoticetemplatelist', data).then(res=>{
        console.log(res)
        this.total = res.count
        this.loading = false
        this.disabledSearch = false
        // res.data.map(item=>{
        //   if (item.status == '0') {
        //     item.state = '有效'
        //   } else {
        //     item.state = '无效'
        //   }
        // })
        this.tableData = res.data
      }).catch(()=>{
        this.loading = false
        this.disabledSearch = false
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.getAddNoticeList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val
      this.getAddNoticeList()
    }
  },
}
</script>

<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}
.Selectnum{
  padding:6px 30px;
  border:1px solid #BFCBF4;
  font-size: 12px;
  color:#666;
  border-radius: 5px;
  margin-bottom:10px;
}
</style>
