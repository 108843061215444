<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="40%"
    :close-on-click-modal="false"
    @close="handleClose">
    <el-card>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-col>
          <el-form-item label="模版名称" prop="templateName">
            <el-input v-model="form.templateName" placeholder="请输入模版名称" maxlength="10" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="起止日期">
            <el-date-picker
              @change="changeTime"
              v-model="form.value1"
              type="daterange"
              :default-time="['00:00:00', '23:59:59']"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="状态" prop="status">
          <el-select v-model="form.status" placeholder="请选择状态">
            <el-option label="启用" value="0"></el-option>
            <el-option label="停用" value="1"></el-option>
          </el-select>
        </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="模版内容" prop="content">
            <el-input type="textarea" placeholder="请输入模版内容" v-model="form.content"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureCommit('form')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { postRequestAnthor } from '@/api'
import { formatTime } from '@/utils/utils'
export default {
  data () {
    return {
      form: {
        templateName: '',
        beginDate: '',
        endDate: '',
        status: '',
        content: '',
        createBy: '1',
        noticeType: '1001'
      },
      rules:{
        templateName: [
          {required: true, message: '请输入模版名称', trigger: 'blur'}
        ],
        beginDate: [
          {required: true, message: '请选择开始时间', trigger: 'change'}
        ],
        endDate: [
          {required: true, message: '请选择结束时间', trigger: 'change'}
        ],
        status: [
          {required: true, message: '请选择通知状态', trigger: 'change'}
        ],
        content: [
          {required: true, message: '请输入通知内容', trigger: 'blur'}
        ],
      },
      dialogVisible: true,
      title: '新建通知模版'
    }
  },
  props: ['info'],
  mounted(){
    if (this.info != '') {
      this.title= '修改通知模版'
      postRequestAnthor('/noticetemplate/querynoticetemplateinfo', {id: this.info.id}).then(res=>{
        console.log(res)
        res.value1= [res.beginDate, res.endDate]
        this.form = res
      })
    }
  },
  methods: {
    handleClose () {
      this.$emit('closeAddmodel')
    },
    changeTime(val){
      this.$forceUpdate()
      console.log(val)
      this.form.beginDate = val[0],
      this.form.endDate = val[1]
    },
    // changeDate1(val){
    //   if (val) {
    //     this.form.beginDate = formatTime(this.form.beginDate)
    //   }
    // },
    // changeDate2(val){
    //   if (val) {
    //     this.form.endDate = formatTime(this.form.endDate)
    //   }
    // },
    sureCommit(form){
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.info != '') {
            postRequestAnthor('/noticetemplate/update',this.form).then(res=>{
            console.log(res)
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.handleClose()
          })
          } else {
            postRequestAnthor('/noticetemplate/addsave',this.form).then(res=>{
              console.log(res)
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.handleClose()
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100% !important;
}
.el-date-editor {
  width: 100%;
}
.el-card {
  margin-top: -25px;
}
</style>